import React, {Component} from 'react';
import postData from "../../lists/florists.json";
import {FloristSearchBox} from '../searchboxfunctions/searchboxfunctions.js';
import {FloristList} from '../listfunctions/listfunctions.js';
import './floristslist.css';


class FloristsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      florists: [...postData],
      searchFlorist: ""
    };
  }
  handleInput = e => {
    this.setState({ searchFlorist: e.target.value });
  };

  render() {
    const { searchFlorist, florists } = this.state;
    let filteredFlorists = florists.filter(florist =>
      Object.values(florist).some(val =>
        val
          .toString()
          .toLowerCase()
          .includes(this.state.searchFlorist.toLowerCase())
      )
    );

    return (
      <div className="App-bodyflorist">
        <header className = 'title'>
          <h1 className = 'header'>Florists</h1>
        </header>
        <div className = 'floristsearch'>
          <FloristSearchBox value={searchFlorist} handleInput={this.handleInput} />
        </div>
        <div >
          <FloristList florists={filteredFlorists} />
        </div>
      </div>
    );
  }
}

export default FloristsList;
