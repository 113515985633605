import React, {Component} from 'react';
import postData from "../../lists/cakemakers.json";
import {CakeMakerList} from '../listfunctions/listfunctions.js';
import {CakeMakerSearchBox} from '../searchboxfunctions/searchboxfunctions.js';
import './cakemakers.css';


class CakeMakersList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cakemakers: [...postData],
      searchCakeMaker: ""
    };
  }
  handleInput = e => {
    this.setState({ searchCakeMaker: e.target.value });
  };

  render() {
    const { searchCakeMaker, cakemakers } = this.state;
    let filteredCakeMakers = cakemakers.filter(cakemaker =>
      Object.values(cakemaker).some(val =>
        val
          .toString()
          .toLowerCase()
          .includes(this.state.searchCakeMaker.toLowerCase())
      )
    );

    return (
      <div className="App-bodycakemakers">
        <header className= 'title'>
          <h1 className = 'header'>Cake Makers</h1>
        </header>
        <div className= 'cakesearch'>
          <CakeMakerSearchBox value={searchCakeMaker} handleInput={this.handleInput} />
        </div>
        <div>
          <CakeMakerList cakemakers={filteredCakeMakers} />
        </div>
      </div>
    );
  }
}

export default CakeMakersList;
