import React, {Component} from 'react';
import postData from "../../lists/prophire.json";
import {ProphireList} from '../listfunctions/listfunctions.js';
import {ProphireSearchBox} from '../searchboxfunctions/searchboxfunctions.js';
import './prophirelist.css';

class PropHireList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      prophires: [...postData],
      searchPropHire: ""
    };
  }
  handleInput = e => {
    this.setState({ searchPropHire: e.target.value });
  };

  render() {
    const { searchPropHire, prophires } = this.state;
    let filteredPropHires = prophires.filter(prophire =>
      Object.values(prophire).some(val =>
        val
          .toString()
          .toLowerCase()
          .includes(this.state.searchPropHire.toLowerCase())
      )
    );

    return (
      <div className="App-bodyprophire">
        <header className = 'title'>
          <h1 className = 'header'>Prop Hire</h1>
        </header>
        <div className = 'propsearch' >
          <ProphireSearchBox value={searchPropHire} handleInput={this.handleInput} />
        </div>
        <div>
          <ProphireList prophires={filteredPropHires} />
        </div>
      </div>
    );
  }
}

export default PropHireList;
