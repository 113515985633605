import React, {Component} from 'react';
import {Accommodation} from '../listlayouts/listlayouts.js';
import {BusHire} from '../listlayouts/listlayouts.js';
import {CakeMaker} from '../listlayouts/listlayouts.js';
import {Caterer} from '../listlayouts/listlayouts.js';
import {Celebrant} from '../listlayouts/listlayouts.js';
import {DressDesigner} from '../listlayouts/listlayouts.js';
import {Firework} from '../listlayouts/listlayouts.js';
import {Florist} from '../listlayouts/listlayouts.js';
import {HairAndMakeup} from '../listlayouts/listlayouts.js';
import {Musician} from '../listlayouts/listlayouts.js';
import {Photographer} from '../listlayouts/listlayouts.js';
import {Prophire} from '../listlayouts/listlayouts.js';
import {Stylist} from '../listlayouts/listlayouts.js';
import {Transport} from '../listlayouts/listlayouts.js';
import Venue from '../listlayouts/listlayouts.js';


export function AccommodationList({accommodations}) {
  let accom = accommodations.map((accommodation, i) => {
    return <Accommodation key = {accommodation.ID} {...accommodation}/>
  });
    return <div className= 'accommdiv'>{accom}</div>
  }

export function BusHireList({bushires}) {
    let bushi = bushires.map((bushire, i) => {
      return <BusHire key = {bushire.ID} {...bushire}/>
    });
      return <div className= 'busdiv'>{bushi}</div>
    }


export function CakeMakerList({cakemakers}) {
      let cakemake = cakemakers.map((cakemaker, i) => {
        return <CakeMaker key = {cakemaker.ID} {...cakemaker}/>
      });
        return <div className='cakediv'>{cakemake}</div>
      }

export function CatererList({caterers}) {
      let cater = caterers.map((caterer, i) => {
        return <Caterer key = {caterer.ID} {...caterer}/>
      });
          return <div className ='caterdiv'>{cater}</div>
      }

export function CelebrantList({celebrants}) {
     let celeb = celebrants.map((celebrant, i) => {
       return  <Celebrant key = {celebrant.ID} {...celebrant}/>
     });
       return <div className ='celebrantdiv'>{celeb}</div>
     }


export function DressDesignList({dressdesigners}) {
      let ddesign = dressdesigners.map((dressdesigner, i) => {
        return <DressDesigner key = {dressdesigner.ID} {...dressdesigner}/>
      });
        return <div className ='dressdiv'>{ddesign}</div>
      }

export function FireWorkList({fireworks}) {
      let fire = fireworks.map((firework, i) => {
        return <Firework key = {firework.ID} {...firework}/>
      });
        return <div className = 'firediv'>{fire}</div>
      }

export function FloristList({florists}) {
      let flower = florists.map((florist, i) => {
        return <Florist key = {florist.ID} {...florist}/>
      });
        return <div className ='floristdiv'>{flower}</div>
      }

export function HairAndMakeupList({hairandmakeups}) {
      let ham = hairandmakeups.map((hairandmakeup, i) => {
        return <HairAndMakeup key = {hairandmakeup.ID} {...hairandmakeup}/>
      });
        return <div className = 'hairdiv'>{ham}</div>
      }

export function MusicianList({musicians}) {
      let music = musicians.map((musician, i) => {
       return <Musician key = {musician.ID} {...musician}/>
            });
       return <div className='musicdiv'>{music}</div>
          }

export function PhotographerList({photographers}) {
      let photo = photographers.map((photographer, i) => {
        return <Photographer key = {photographer.ID} {...photographer}/>
      });
        return <div className ='photographdiv'>{photo}</div>
      }

export function ProphireList({prophires}) {
      let props = prophires.map((prophire, i) => {
       return <Prophire key = {prophire.ID} {...prophire}/>
            });
       return <div className = 'propdiv'>{props}</div>
          }

export function StylistList({stylists}) {
      let style = stylists.map((stylist, i) => {
       return <Stylist key = {stylist.ID} {...stylist}/>
            });
       return <div className = 'stylediv'>{style}</div>
          }

export function TransportList({transports}) {
      let transp = transports.map((transport, i) => {
       return <Transport key = {transport.ID} {...transport}/>
            });
       return <div className = 'transdiv'>{transp}</div>
          }

function VenueList({venues}) {
     let venu = venues.map((venue, i) => {
       return <Venue key = {venue.ID} {...venue}/>
     });
       return <div className='venuediv'>{venu}</div>
     }

export default VenueList;
