import React, {Component} from 'react';
import './socialmedia.css';

class Fbook extends Component{
  render(){
    return(
      <div className='Fbook-icon'>
      </div>
    );
  }
}

export class Insta extends Component{
  render(){
    return(
      <div className='Insta-icon'>
      </div>
    );
  }
}

export class Web extends Component{
  render(){
    return(
      <div className='Web-icon'>
      </div>
    );
  }
}

export class Home extends Component{
  render(){
    return(
      <div className='Home-icon'>
      </div>
    )
  }
}

export default Fbook;
