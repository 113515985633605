import React, {Component} from 'react';
import postData from "../../lists/transport.json";
import {TransportList} from '../listfunctions/listfunctions.js';
import {TransportSearchBox} from '../searchboxfunctions/searchboxfunctions.js';
import './transportlist.css';

class TransportsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transports: [...postData],
      searchTransport: ""
    };
  }
  handleInput = e => {
    this.setState({ searchTransport: e.target.value });
  };

  render() {
    const { searchTransport, transports } = this.state;
    let filteredTransports = transports.filter(transport =>
      Object.values(transport).some(val =>
        val
          .toString()
          .toLowerCase()
          .includes(this.state.searchTransport.toLowerCase())
      )
    );

    return (
      <div className="App-bodytransport">
        <header className='title'>
          <h1 className = 'header'>Transport</h1>
        </header>
        <div className= 'transsearch'>
          <TransportSearchBox value={searchTransport} handleInput={this.handleInput} />
        </div>
        <div>
          <TransportList transports={filteredTransports} />
        </div>
      </div>
    );
  }
}

export default TransportsList;
