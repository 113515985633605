import React, {Component} from 'react';
import './searchboxfunctions.css';


export function AccommodationSearchBox({value, handleInput}) {
  return (
    <div>
      <input onChange={handleInput} value={value} type ='text' className = 'searchBox' placeholder = 'Search by names, styles, locations, postcodes etc...'/>
    </div>
  );
}

export function BusHireSearchBox({value, handleInput}) {
  return (
    <div>
      <input onChange={handleInput} value={value} type ='text' className = 'searchBox' placeholder = 'Search by names, styles, locations, postcodes etc...'/>
    </div>
  );
}

export function CakeMakerSearchBox({value, handleInput}) {
  return (
    <div>
      <input onChange={handleInput} value={value} type ='text' className = 'searchBox' placeholder = 'Search by names, styles, locations, postcodes etc...'/>
    </div>
  );
}

export function CatererSearchBox({value, handleInput}) {
  return (
    <div>
      <input onChange={handleInput} value={value} type ='text' className = 'searchBox' placeholder = 'Search by names, styles, locations, postcodes etc...'/>
    </div>
  );
}

export function CelebrantSearchBox({value, handleInput}) {
  return (
    <div>
      <input onChange={handleInput} value={value} type ='text' className = 'searchBox' placeholder = 'Search by names, styles, locations, postcodes etc...'/>
    </div>
  );
}


export function DressDesignerSearchBox({value, handleInput}) {
  return (
    <div>
      <input onChange={handleInput} value={value} type ='text' className = 'searchBox' placeholder = 'Search by names, styles, locations, postcodes etc...'/>
    </div>
  );
}

export function FireWorkSearchBox({value, handleInput}) {
  return (
    <div>
      <input onChange={handleInput} value={value} type ='text' className = 'searchBox' placeholder = 'Search by names, styles, locations, postcodes etc...'/>
    </div>
  );
}

export function FloristSearchBox({value, handleInput}) {
  return (
    <div>
      <input onChange={handleInput} value={value} type ='text' className = 'searchBox' placeholder = 'Search by names, styles, locations, postcodes etc...'/>
    </div>
  );
}

export function HairAndMakeupSearchBox({value, handleInput}) {
  return (
    <div>
      <input onChange={handleInput} value={value} type ='text' className = 'searchBox' placeholder = 'Search by names, styles, locations, postcodes etc...'/>
    </div>
  );
}

export function MusicianSearchBox({value, handleInput}) {
  return (
    <div>
      <input onChange={handleInput} value={value} type ='text' className = 'searchBox' placeholder = 'Search by names, styles, locations, postcodes etc...'/>
    </div>
  );
}

export function PhotographerSearchBox({value, handleInput}) {
  return (
    <div>
      <input onChange={handleInput} value={value} type ='text' className = 'searchBox' placeholder = 'Search by names, styles, locations, postcodes etc...'/>
    </div>
  );
}

export function ProphireSearchBox({value, handleInput}) {
  return (
    <div>
      <input onChange={handleInput} value={value} type ='text' className = 'searchBox' placeholder = 'Search by names, styles, locations, postcodes etc...'/>
    </div>
  );
}

export function StylistSearchBox({value, handleInput}) {
  return (
    <div>
      <input onChange={handleInput} value={value} type ='text' className = 'searchBox' placeholder = 'Search by names, styles, locations, postcodes etc...'/>
    </div>
  );
}

export function TransportSearchBox({value, handleInput}) {
  return (
    <div>
      <input onChange={handleInput} value={value} type ='text' className = 'searchBox' placeholder = 'Search by names, styles, locations, postcodes etc...'/>
    </div>
  );
}

function VenueSearchBox({value, handleInput}) {
  return (
    <div>
      <input onChange={handleInput} value={value} type ='text' className = 'searchBox' placeholder = 'Search by names, styles, locations, postcodes etc...'/>
    </div>
  );
}

export default VenueSearchBox;
