import React, {Component} from 'react';
import postData from "../../lists/photographers.json";
import {PhotographerSearchBox} from '../searchboxfunctions/searchboxfunctions.js';
import {PhotographerList} from '../listfunctions/listfunctions.js';
import './photographerslist.css';



class PhotographersList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      photographers: [...postData],
      searchPhotographer: ""
    };
  }
  handleInput = e => {
    this.setState({ searchPhotographer: e.target.value });
  };

  render() {
    const { searchPhotographer, photographers } = this.state;
    let filteredPhotographers = photographers.filter(photographer =>
      Object.values(photographer).some(val =>
        val
          .toString()
          .toLowerCase()
          .includes(this.state.searchPhotographer.toLowerCase())
      )
    );

    return (
      <div className="App-bodyphotographer">
        <header className='title'>
          <h1 className= 'headerphoto'>Photography/Videography</h1>
        </header>
        <div className ='photosearch'>
          <PhotographerSearchBox value={searchPhotographer} handleInput={this.handleInput} />
        </div>
        <div>
          <PhotographerList photographers={filteredPhotographers} />
        </div>
      </div>
    );
  }
}

export default PhotographersList;
