import React, {Component} from 'react';
import postData from "../../lists/accommodation.json";
import {AccommodationList} from '../listfunctions/listfunctions.js';
import {AccommodationSearchBox} from '../searchboxfunctions/searchboxfunctions.js';
import './accommodationlist.css';

class AccommodationsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accommodations: [...postData],
      searchAccommodation: ""
    };
  }
  handleInput = e => {
    this.setState({ searchAccommodation: e.target.value });
  };

  render() {
    const { searchAccommodation, accommodations } = this.state;
    let filteredAccommodations = accommodations.filter(accommodation =>
      Object.values(accommodation).some(val =>
        val
          .toString()
          .toLowerCase()
          .includes(this.state.searchAccommodation.toLowerCase())
      )
    );

    return (
      <div className="App-bodyaccommodation">
        <header className= 'title'>
          <h1 className = 'header'>Accommodation</h1>
        </header>
        <div className= 'accommsearch'>
          <AccommodationSearchBox value={searchAccommodation} handleInput={this.handleInput} />
          <AccommodationList accommodations={filteredAccommodations} />
        </div>
      </div>
    );
  }
}

export default AccommodationsList;
