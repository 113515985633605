import React, {Component} from 'react';
import postData from "../../lists/bushire.json";
import {BusHireList} from '../listfunctions/listfunctions.js';
import {BusHireSearchBox} from '../searchboxfunctions/searchboxfunctions.js';
import './bushire.css';


class BusHiresList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bushires: [...postData],
      searchBushire: ""
    };
  }
  handleInput = e => {
    this.setState({ searchBushire: e.target.value });
  };

  render() {
    const { searchBushire, bushires } = this.state;
    let filteredBushires = bushires.filter(bushire =>
      Object.values(bushire).some(val =>
        val
          .toString()
          .toLowerCase()
          .includes(this.state.searchBushire.toLowerCase())
      )
    );

    return (
      <div className="App-bodybushire">
        <header className= 'title'>
          <h1 className = 'header'>Bus Hire</h1>
        </header>
        <div className= 'bussearch'>
          <BusHireSearchBox value={searchBushire} handleInput={this.handleInput} />
        </div>
        <div>
          <BusHireList bushires={filteredBushires} />
        </div>
      </div>
    );
  }
}

export default BusHiresList;
