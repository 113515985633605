import React, {Component} from 'react';
import postData from "../../lists/musicians.json";
import {MusicianSearchBox} from '../searchboxfunctions/searchboxfunctions.js';
import {MusicianList} from '../listfunctions/listfunctions.js';
import './musicianslist.css';


class MusiciansList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      musicians: [...postData],
      searchMusician: ""
    };
  }
  handleInput = e => {
    this.setState({ searchMusician: e.target.value });
  };

  render() {
    const { searchMusician, musicians } = this.state;
    let filteredMusicians = musicians.filter(musician =>
      Object.values(musician).some(val =>
        val
          .toString()
          .toLowerCase()
          .includes(this.state.searchMusician.toLowerCase())
      )
    );

    return (
      <div className="App-bodymusician">
        <header className='title'>
          <h1 className = 'header'>Musicians</h1>
        </header>
        <div className ='musicsearch'>
          <MusicianSearchBox value={searchMusician} handleInput={this.handleInput} />
        </div>
        <div>
          <MusicianList musicians={filteredMusicians} />
        </div>
      </div>
    );
  }
}

export default MusiciansList;
