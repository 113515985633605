import React from 'react';
import DrawerToggleButton from '../SideDrawer/DrawerToggleButton';
import './Toolbar.css';
import {Link} from 'react-router-dom';
import {Insta} from '../socialmedia/socialmedia.js';
import Fbook from '../socialmedia/socialmedia.js';
import {Home} from '../socialmedia/socialmedia.js';

const toolbar = props => (
  <header className ='toolbar'>
    <nav className='toolbar_navigation'>
      <div className='toolbar_toggle-button'>
        <DrawerToggleButton click={props.drawerClickHandler}/>
      </div>
      <img src='./logodesigns/headerdesign.png' alt='headerlogo' className='headerlogo'/>
        <div className='sociallogos'>
           <a href='https://www.instagram.com/simpleas_ido/' alt= 'instagram' target = '_blank' rel='noreferrer noopener' ><Insta/></a>
           <a href='https://m.facebook.com/simpleasido#_=_' alt = 'facebook' target = '_blank' rel='noreferrer noopener' ><Fbook/></a>
           <Link to='/'><Home/></Link>
        </div>
    </nav>
  </header>
);


export default toolbar;
