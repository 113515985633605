import React, {Component} from 'react';
import postData from "../../lists/venues.json";
import VenueSearchBox from '../searchboxfunctions/searchboxfunctions.js';
import VenueList from '../listfunctions/listfunctions.js';
import './venueslist.css';

class VenuesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      venues: [...postData],
      searchVenue: ""
    };
  }
  handleInput = e => {
    this.setState({ searchVenue: e.target.value });
  };

  render() {
    const { searchVenue, venues } = this.state;
    let filteredVenues = venues.filter(venue =>
      Object.values(venue).some(val =>
        val
          .toString()
          .toLowerCase()
          .includes(this.state.searchVenue.toLowerCase())
      )
    );

    return (
      <div className="App-bodyvenue">
        <header className='title'>
          <h1 className = 'header'>Venues</h1>
        </header>
        <div className='venuesearch'>
          <VenueSearchBox value={searchVenue} handleInput={this.handleInput} />
        </div>
        <div>
          <VenueList venues={filteredVenues} />
        </div>
      </div>
    );
  }
}

export default VenuesList;
