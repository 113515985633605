import React, {Component} from 'react';
import postData from "../../lists/caterers.json";
import {CatererList} from '../listfunctions/listfunctions.js';
import {CatererSearchBox} from '../searchboxfunctions/searchboxfunctions.js';
import './caterers.css';

class CaterersList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      caterers: [...postData],
      searchCaterer: ""
    };
  }
  handleInput = e => {
    this.setState({ searchCaterer: e.target.value });
  };

  render() {
    const { searchCaterer, caterers } = this.state;
    let filteredCaterers = caterers.filter(caterer =>
      Object.values(caterer).some(val =>
        val
          .toString()
          .toLowerCase()
          .includes(this.state.searchCaterer.toLowerCase())
      )
    );

    return (
      <div className="App-bodycaterer">
        <header className = 'title'>
          <h1 className = 'header'>Catering</h1>
        </header>
        <div className ='catersearch'>
          <CatererSearchBox value={searchCaterer} handleInput={this.handleInput} />
        </div>
        <div>
          <CatererList caterers={filteredCaterers} />
        </div>
      </div>
    );
  }
}

export default CaterersList;
