import React, {Component} from 'react';
import postData from "../../lists/dressdesign.json";
import {DressDesignList} from '../listfunctions/listfunctions.js';
import {DressDesignerSearchBox} from '../searchboxfunctions/searchboxfunctions.js';
import './dressdesignlist.css';

class DressDesignerList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dressdesigners: [...postData],
      searchDressDesigner: ""
    };
  }
  handleInput = e => {
    this.setState({ searchDressDesigner: e.target.value });
  };

  render() {
    const { searchDressDesigner, dressdesigners } = this.state;
    let filteredDressDesigners = dressdesigners.filter(dressdesigner =>
      Object.values(dressdesigner).some(val =>
        val
          .toString()
          .toLowerCase()
          .includes(this.state.searchDressDesigner.toLowerCase())
      )
    );

    return (
      <div className="App-bodydressdesigner">
        <header className = 'title'>
          <h1 className = 'header'>Dress Designers</h1>
        </header>
        <div className = 'dresssearch' >
          <DressDesignerSearchBox value={searchDressDesigner} handleInput={this.handleInput} />
        </div>
        <div>
          <DressDesignList dressdesigners={filteredDressDesigners} />
        </div>
      </div>
    );
  }
}

export default DressDesignerList;
