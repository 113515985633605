import React from 'react';
import './SideDrawer.css';
import {Link} from 'react-router-dom';


const sideDrawer =  props => {

  let drawerClasses = 'side-drawer';
  if (props.show) {
    drawerClasses= 'side-drawer open'
  }

 return(
  <nav className={drawerClasses}>
    <ul>
      <li className = 'listItems'><Link to='/'>Home</Link></li>
      <li className = 'listItems'><Link to='/celebrants'>Celebrants</Link></li>
      <li className = 'listItems'><Link to='/photographers & videographers'>Photography & Videography</Link></li>
      <li className = 'listItems'><Link to='/musicians'>Musicians</Link></li>
      <li className = 'listItems'><Link to='/venues'>Venues</Link></li>
      <li className = 'listItems'><Link to='/hair & makeup'>Hair & MakeUp</Link></li>
      <li className = 'listItems'><Link to='/florists'>Florists</Link></li>
      <li className = 'listItems'><Link to='/dress designers & shops'>Dress Designers & Shops</Link></li>
      <li className = 'listItems'><Link to='/wedding stylists'>Wedding Stylists</Link></li>
      <li className = 'listItems'><Link to='/catering'>Catering</Link></li>
      <li className = 'listItems'><Link to='/cake makers'>Cake Makers</Link></li>
      <li className = 'listItems'><Link to='/bus hire'>Bus Hire</Link></li>
      <li className = 'listItems'><Link to='/transport'>Transport</Link></li>
      <li className = 'listItems'><Link to='/accommodation'>Accommodation</Link></li>
      <li className = 'listItems'><Link to='/prop hire'>Prop Hire</Link></li>
      <li className = 'listItems'><Link to='/fireworks'>Fireworks</Link></li>
      <li className = 'listItems'><Link to='/about us'>About Us</Link></li>
      <li className = 'listItems'><Link to='/contact us'>Contact Us</Link></li>
      <li className = 'listItems'><Link to='/new listings'>New Listings</Link></li>
      <li className = 'listItems'><Link to='/privacy policy'>Privacy Policy</Link></li>
      <li className = 'listItems'><Link to='/terms of use'>Terms Of Use</Link></li>
    </ul>
  </nav>
);
};

export default sideDrawer;
