import React, {Component} from 'react';
import './footer.css';
import {Link} from 'react-router-dom';
import {Home} from '../socialmedia/socialmedia.js';


class Footer extends Component{
    render(){
      return(
        <div className='Footer'>
        <Link to='/terms of use'> -Terms Of Use- </Link><Link to='/privacy policy'> -Privacy Policy- </Link><Link to='/about us'> -About Us- </Link><Link to='/'><Home/></Link>
        </div>
      )
    }
  }

export default Footer;
