import React, {Component} from 'react';
import postData from "../../lists/hair&makeup.json";
import {HairAndMakeupList} from '../listfunctions/listfunctions.js';
import {HairAndMakeupSearchBox} from '../searchboxfunctions/searchboxfunctions.js';
import './hair&makeuplist.css';

class HairMakeupList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hairandmakeups: [...postData],
      searchHairandmakeup: ""
    };
  }
  handleInput = e => {
    this.setState({ searchHairandmakeup: e.target.value });
  };

  render() {
    const { searchHairandmakeup, hairandmakeups } = this.state;
    let filteredHairandmakeups = hairandmakeups.filter(hairandmakeup =>
      Object.values(hairandmakeup).some(val =>
        val
          .toString()
          .toLowerCase()
          .includes(this.state.searchHairandmakeup.toLowerCase())
      )
    );

    return (
      <div className="App-bodyhairandmakeup">
        <header className='title'>
          <h1 className = 'header'>Hair & Makeup</h1>
        </header>
        <div className='hairsearch'>
          <HairAndMakeupSearchBox value={searchHairandmakeup} handleInput={this.handleInput} />
        </div>
        <div>
          <HairAndMakeupList hairandmakeups={filteredHairandmakeups} />
        </div>
      </div>
    );
  }
}

export default HairMakeupList;
