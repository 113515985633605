import React, {Component} from 'react'
import AccommodationsList from '../accommodationlist/accommodationlist.js';
import BusHiresList from '../bushirelist/bushire.js';
import CakeMakersList from '../cakemakerslist/cakemakers.js';
import CaterersList from '../catererslist/caterers.js';
import CelebrantsList from '../celebrantslist/celebrantslist.js';
import DressDesignerList from '../dressdesignlist/dressdesignlist.js';
import FireworksList from '../fireworkslist/fireworkslist.js';
import HairMakeupList from '../hair&makeuplist/hair&makeuplist.js';
import VenuesList from '../venueslist/venueslist.js';
import MusiciansList from '../musicianslist/musicianslist.js';
import PhotographersList from '../photographerslist/photographerslist.js';
import PropHireList from '../prophirelist/prophirelist.js';
import TransportsList from '../transportlist/transportlist.js';
import StylistsList from '../stylistslist/stylistslist.js';
import FloristsList from '../floristslist/floristslist.js';
import Aboutus from '../aboutus/aboutus.js';
import ContactUs from '../contactus/contactus.js';
import NewListings from '../newlistings/newlistings.js';
import CarouselPage from '../homepage/homepage.js';
import PrivacyPolicy from '../privacypolicy/privacypolicy.js';
import TermsOfUse from '../termsofuse/termsofuse.js';

const routes = [
  {
  path: '/',
  exact: true,
  sidebar: () => <div></div>,
  main: () => <div>
                <CarouselPage/>
              </div>
  },
  {
  path: '/celebrants',
  main: () => <CelebrantsList/>
  },
  {
  path: '/photographers & videographers',
  main: () => <PhotographersList/>
  },
  {
  path: '/musicians',
  main: () => <MusiciansList/>
  },
  {
  path: '/venues',
  main: () => <VenuesList/>
  },
  {
  path: '/hair & makeup',
  main: () => <HairMakeupList/>
  },
  {
  path: '/florists',
  main: () => <FloristsList/>
  },
  {
  path: '/dress designers & shops',
  main: () => <DressDesignerList/>
  },
  {
  path: '/wedding stylists',
  main: () => <StylistsList/>
  },
  {
  path: '/catering',
  main: () => <CaterersList/>
  },
  {
  path: '/cake makers',
  main: () => <CakeMakersList/>
  },
  {
  path: '/bus hire',
  main: () => <BusHiresList/>
  },
  {
  path: '/transport',
  main: () => <TransportsList/>
  },
  {
  path: '/accommodation',
  main: () => <AccommodationsList/>
  },
  {
  path: '/prop hire',
  main: () => <PropHireList/>
  },
  {
  path: '/fireworks',
  main: () => <FireworksList/>
  },
  {
  path: '/about us',
  main: () => <Aboutus/>
  },
  {
  path: '/contact us',
  main: () => <ContactUs/>
  },
  {
  path: '/privacy policy',
  main: () => <PrivacyPolicy/>
  },
  {
  path: '/terms of use',
  main: () => <TermsOfUse/>
  },
  {
  path: '/new listings',
  main: () => <NewListings/>
  },
];


export default routes;
