import React, {Component} from 'react';
import postData from "../../lists/fireworks.json";
import {FireWorkList} from '../listfunctions/listfunctions.js';
import {FireWorkSearchBox} from '../searchboxfunctions/searchboxfunctions.js';
import './fireworkslist.css';

class FireworksList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fireworks: [...postData],
      searchFirework: ""
    };
  }
  handleInput = e => {
    this.setState({ searchFirework: e.target.value });
  };

  render() {
    const { searchFirework, fireworks } = this.state;
    let filteredFireworks = fireworks.filter(firework =>
      Object.values(firework).some(val =>
        val
          .toString()
          .toLowerCase()
          .includes(this.state.searchFirework.toLowerCase())
      )
    );

    return (
      <div className="App-bodyfireworks">
        <header className='title'>
          <h1 className = 'header'>Fireworks</h1>
        </header>
        <div className='firesearch'>
          <FireWorkSearchBox value={searchFirework} handleInput={this.handleInput} />
        </div>
        <div>
          <FireWorkList fireworks={filteredFireworks} />
        </div>
      </div>
    );
  }
}

export default FireworksList;
