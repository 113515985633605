import React, {Component} from 'react';
import './aboutus.css';
import {Link} from 'react-router-dom';

class Aboutus extends Component{
  render(){
    return(
      <div className='aboutus'>
      <div className='background'>
      <div className='abouttitle'>
      About Us.
      </div>
      <div className='body'>
      Simple As I Do (SAID) was created in 2019, with the idea of making wedding planning less stressful and time consuming.
      By bringing more options to the finger tips of our bride and grooms to be. Free to use with no sign ups or accounts required.
      Simply type your search terms into our searchbar, and finding vendors and suppliers for your special day really is as Simple As I Do !
      <br/>
      <br/>
      And for our vendors and suppliers we aim to bring more weddings to you within the locations you are willing to make yourself available. Once potential clients
      narrow down their searches and find you they are directed to any of your websites or social platforms you have choosen to list with us, so they can easily get in
      contact with you.
      <br/>
      <br/>
      Get in contact with us today via our <Link to='/contact us'>Contact Us</Link> page to get started advertising with us. All first time listings with SAID
      receive a free 12 month listing !
      </div>
      </div>
      </div>
    )
  }
}

export default Aboutus;
