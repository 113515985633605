import React, {Component} from 'react';
import './privacypolicy.css';

class PrivacyPolicy extends Component{
  render(){
    return(
<div className='privacypolicy'>
<h1>Privacy Policy</h1>
<br/>
<br/>
<p>
<strong>Simple As I Do (SAID)</strong>is committed to providing quality services to you and this policy outlines our ongoing obligations to you in respect of how we manage your Personal Information.
We have adopted the Australian Privacy Principles (APPs) contained in the Privacy Act 1988 (Cth) (the Privacy Act). The NPPs govern the way in which we collect, use, disclose, store, secure and dispose of your Personal Information.
A copy of the Australian Privacy Principles may be obtained from the website of The Office of the Australian Information Commissioner at www.aoic.gov.au
</p>
<br/>

<p>
<h3>What is Personal Information and why do we collect it?</h3>
<br/>
Personal Information is information or an opinion that identifies an individual. Examples of Personal Information we collect include: names, addresses, email addresses, phone and facsimile numbers.
This Personal Information is obtained in many ways including interviews, correspondence, by telephone and facsimile, by email, via our website www.simpleasido.com.au, from your website, from social media and publications, from other publicly available sources and from third parties. We don’t guarantee website links or policy of authorised third parties.
We collect your Personal Information for the primary purpose of providing our services to you, providing information to our clients and marketing. We may also use your Personal Information for secondary purposes closely related to the primary purpose, in circumstances where you would reasonably expect such use or disclosure. You may unsubscribe from our mailing/marketing lists at any time by contacting us in writing.
When we collect Personal Information we will, where appropriate and where possible, explain to you why we are collecting the information and how we plan to use it.
</p>
<br/>

<p>
<h3>Sensitive Information</h3>
<br/>
Sensitive information is defined in the Privacy Act to include information or opinion about such things as an individual's racial or ethnic origin, political opinions, membership of a political association, religious or philosophical beliefs, membership of a trade union or other professional body, criminal record or health information. There would rarely be a situation where (SAID) would need to collect this sort of information from either our vendors/suppliers or website traffic.
Sensitive information will be used by us only:
•	For the primary purpose for which it was obtained
•	For a secondary purpose that is directly related to the primary purpose
•	With your consent; or where required or authorised by law.
</p>
<br/>

<p>
<h3>Third Parties</h3>
<br/>
Where reasonable and practicable to do so, we will collect your Personal Information only from you. However, in some circumstances we may be provided with information by third parties. In such a case we will take reasonable steps to ensure that you are made aware of the information provided to us by the third party.
</p>
<br/>

<p>
<h3>Disclosure of Personal Information</h3>
<br/>
<strong>SAID will not</strong> distribute or sell on any personal information for our own benefit, however understand that the nature of an advertising website means that any information you choose to list with us may be available to the public for browsing.
Your Personal Information may be disclosed in a number of circumstances including the following:
•	Third parties where you consent to the use or disclosure; and
•	Where required or authorised by law.
</p>
<br/>

<p>
<h3>Security of Personal Information</h3>
<br/>
Your Personal Information is stored in a manner that reasonably protects it from misuse and loss and from unauthorized access, modification or disclosure.
When your Personal Information is no longer needed for the purpose for which it was obtained, we will take reasonable steps to destroy or permanently de-identify your Personal Information. However, most of the Personal Information is or will be stored in client files which will be kept by us for a minimum of 7 years.
</p>
<br/>

<p>
<h3>Access to your Personal Information</h3>
<br/>
You may access the Personal Information we hold about you and to update/remove or correct it, subject to no exceptions. If you wish to access your Personal Information, please contact us in via our email. (listings@simpleasido.com.au)
</p>

</div>
    )
  }
}

export default PrivacyPolicy
