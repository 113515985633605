import React, { Component } from 'react';
import './contactus.css';
import axios from "axios";

class ContactUs extends React.Component {
  constructor(props){
  super(props);
  this.state = {name: "", surname: "", email: "", message: ""};
}

handleForm = e => {
    axios.post(
      "https://formcarry.com/s/HHMKw-VDMjm",
      this.state,
      {headers: {"Accept": "application/json"}}
      )
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });

    e.preventDefault();
  }

handleFields = e => this.setState({ [e.target.name]: e.target.value });

  render() {
    return (
      <div className='ContactUs'>
        <header className='Form-header'>
          <h1 className='title1'>ContactUs</h1>
        </header>
        <br/>
        <div className='Contactpara'>
        <p>
        For general enquires, please contact us through the below form and
        we will respond back to you within 2-5 business days.
        </p>
        </div>
        <br/>
       <div className='Contact'>
         <form onSubmit={this.handleForm} className='form'>
         <input type="text" id="name" name="name" onChange={this.handleFields} placeholder='First Name' className='infobox'/>
         <br/>
         <input type="text" id="surname" name="surname" onChange={this.handleFields} placeholder='Last Name' className='infobox'/>
         <br/>
         <input type="text" id="business" name="business" onChange={this.handleFields} placeholder='Business Name' className='infobox'/>
         <br/>
         <input type="email" id="email" name="email" onChange={this.handleFields} placeholder='Email' className='infobox'/>
         <br/>
         <textarea name="message" id="message" className="Input" onChange={this.handleFields} placeholder='Please type your enquiry here.' className='enquiry' ></textarea>
         <br/>
         <button type="submit" className='Send'>Send</button>
        </form>
        </div>
      </div>

    );
      }
}

 export default ContactUs;
