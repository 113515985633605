import React, {Component} from 'react';
import postData from "../../lists/celebrants.json";
import {CelebrantList} from '../listfunctions/listfunctions.js';
import {CelebrantSearchBox} from '../searchboxfunctions/searchboxfunctions.js';
import './celebrantslist.css';

class CelebrantsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      celebrants: [...postData],
      searchCelebrant: ""
    };
  }
  handleInput = e => {
    this.setState({ searchCelebrant: e.target.value });
  };

  render() {
    const { searchCelebrant, celebrants } = this.state;
    let filteredCelebrants = celebrants.filter(celebrant =>
      Object.values(celebrant).some(val =>
        val
          .toString()
          .toLowerCase()
          .includes(this.state.searchCelebrant.toLowerCase())
      )
    );

    return (
      <div className="App-bodycelebrants">
        <header className='title'>
          <h1 className = 'header'>Celebrants</h1>
        </header>
        <div className='celebsearch'>
          <CelebrantSearchBox value={searchCelebrant} handleInput={this.handleInput} />
        </div>
        <div>
          <CelebrantList celebrants={filteredCelebrants} />
        </div>
      </div>
    );
  }
}

export default CelebrantsList;
