import React, {Component} from 'react'
import {BrowserRouter, Route} from 'react-router-dom';
import ScrollIntoView from './components/scrollrestore/scrollrestore.js';
import routes from './components/routes/routes.js';
import Toolbar from './components/Toolbar/Toolbar';
import SideDrawer from './components/SideDrawer/SideDrawer';
import Backdrop from './components/BackDrop/BackDrop';
import Footer from './components//footer/footer.js';
import './App.css';




class App extends Component {
state ={
  sideDrawerOpen: false
};

drawerToggleClickHandler = () => {
  this.setState((prevState) => {
    return {sideDrawerOpen: !prevState.sideDrawerOpen}
  });
};

backdropClickHandler = () => {
  this.setState({sideDrawerOpen: false});
};

  render() {

    let backdrop;

    if (this.state.sideDrawerOpen) {
      backdrop = <Backdrop click = {this.backdropClickHandler}/>
    }

    return(
      <div className = 'WholePage'>
        <BrowserRouter>
        <ScrollIntoView>
        <Toolbar drawerClickHandler={this.drawerToggleClickHandler}/>
        <SideDrawer show={this.state.sideDrawerOpen}/>
        {backdrop}
        <main className='Main'>
          {routes.map((route) => (
           <Route
              key={route.path}
              path={route.path}
              exact={route.exact}
              component={route.main}
            />
          ))}
        </main>
        <Footer/>
        </ScrollIntoView>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
