import React, { Component } from 'react';
import './newlistings.css';
import axios from "axios";

class NewListings extends React.Component {
  constructor(props){
  super(props);
  this.state = {businesstype:"", selectstate:"", streetaddress:"", suburb:"", postcode:"", businessname:"", abn:"", facelink:"",
  instalink:"", weblink:"", social:"",  email: "", message: ""};
}

    handleForm = e => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);

    axios
      .post("https://formcarry.com/s/h6LKLAtexF-", data, {
        headers: { Accept: "application/json" }
      })
      .then(function(response) {
        // access response.data in order to check formcarry response
        if (response.data.success) {
          // handle success
          alert("success");
        } else {
          // handle error
          alert(response.data.message);
          console.log(response.data.message);
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  handleFields = e => this.setState({ [e.target.name]: e.target.value });

  render() {
    return (
      <div className='NewListings'>
        <header className='Form-header'>
          <h1 className='title1'>New Listings</h1>
        </header>
        <br/>
        <div className='newlistpara'>
        <p>
        For New Listings with SAID, please fill out the below form with your business details and we will contact you within 2-5 business days.
        </p>
        <br/>
        <p>
        All new listings recieve 12 months free, with no sign-ups or payment details required there is nothing to lose! Start growing your business with us today...
        </p>
        </div>
        <br/>
       <div className='Contact'>
         <form onSubmit={this.handleForm} method="POST" encType="multipart/form-data" className='form'>
         <select type="text" id="businesstype" name="businesstype" onChange={this.handleFields} className='busiselectbox'>
           <option value="businesstype">Select Business Type</option>
           <option value="Celebrants">Celebrants</option>
           <option value="Photography & Videography">Photography & Videography</option>
           <option value="Musicians">Musicians</option>
           <option value="Venues">Venues</option>
           <option value="Hair & Make-up">Hair & Make-up</option>
           <option value="Florists">Florists</option>
           <option value="Dress Designers & Shops">Dress Designers & Shops</option>
           <option value="Wedding Stylists">Wedding Stylists</option>
         </select>
         <br/>
         <select type="text" id="selectstate" name="selectstate" onChange={this.handleFields} className='busiselectbox'>
           <option value="select state">Select State</option>
           <option value="NSW">NSW</option>
           <option value="NT">NT</option>
           <option value="QLD">QLD</option>
           <option value="SA">SA</option>
           <option value="TAS">TAS</option>
           <option value="VIC">VIC</option>
           <option value="WA">WA</option>
         </select>
         <br/>
         <input type="text" id="streetaddress" name="streetaddress" onChange={this.handleFields} placeholder='Street Address' className='infobox'/>
         <br/>
         <input type="text" id="suburb" name="suburb" onChange={this.handleFields} placeholder='Suburb' className='infobox'/>
         <br/>
         <input type="text" id="postcode" name="postcode" onChange={this.handleFields} placeholder='Post Code' className='infobox'/>
         <br/>
         <input type="text" id="businessname" name="businessname" onChange={this.handleFields} placeholder='Business Name' className='infobox'/>
         <br/>
         <input type="text" id="abn" name="abn" onChange={this.handleFields} placeholder='Australian Business Number (ABN)' className='infobox'/>
         <br/>
         <input type="text" id="facelink" name="facelink" onChange={this.handleFields} placeholder='Facebook Link' className='infobox'/>
         <br/>
         <input type="text" id="instalink" name="instalink" onChange={this.handleFields} placeholder='Instagram Link' className='infobox'/>
         <br/>
         <input type="text" id="weblink" name="weblink" onChange={this.handleFields} placeholder='Website Link' className='infobox'/>
         <br/>
         <input type="text" id="social" name="social" onChange={this.handleFields} placeholder='Additional Social Media Links' className='infobox'/>
         <br/>
         <input type="email" id="email" name="email" onChange={this.handleFields} placeholder='Email' className='infobox'/>
         <br/>
         <label htmlFor="Upload Advertising Image" className='imageup'>Upload Advertising Image</label>
         <br/>
         <input type="file" id="image" name="image" onChange={this.handleFields} placeholder='Advertisement Image' className='infobox'></input>
         <br/>
         <textarea name="message" id="message" className="Input" onChange={this.handleFields} placeholder='Tell us about your Business/Service, including what regions you service' className='enquiry' ></textarea>
         <br/>
         <button type="submit" className='Send'>Send</button>
        </form>
        </div>
      </div>
    );
  }
}

export default NewListings;
