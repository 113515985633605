import React, {Component} from 'react';
import Fbook from '../socialmedia/socialmedia.js';
import {Insta} from '../socialmedia/socialmedia.js';
import {Web} from '../socialmedia/socialmedia.js';
import './listlayouts.css';



export function Accommodation(postDetail){
  return(
    <div className='Accommodation'>
    <div className='info'>
    <p>Name: {postDetail.Name}</p>
    <p>Town: {postDetail.Town}</p>
    <p>State: {postDetail.State} </p>
    <p>PostCode: {postDetail.PostCode}</p>
    </div>
    <img src={postDetail.Image} className='photo' alt='vendor photo'/>
    <div className='icons'>
    {postDetail.Fbook ? <a href={postDetail.Fbook} alt = 'facebook' target = '_blank' rel='noreferrer noopener'><Fbook/></a> : null}
    {postDetail.Insta ? <a href={postDetail.Insta} alt= 'instagram' target = '_blank' rel='noreferrer noopener'><Insta/></a> : null}
    {postDetail.Web ? <a href={postDetail.Web} alt= 'Website URL' target = '_blank' rel='noreferrer noopener'><Web/></a> : null}
    </div>
    </div>
  )
}

export function BusHire(postDetail){
  return(
    <div className='BusHire'>
    <div className='info'>
    <p>Name: {postDetail.Name}</p>
    <p>Town: {postDetail.Town}</p>
    <p>State: {postDetail.State} </p>
    <p>PostCode: {postDetail.PostCode}</p>
    </div>
    <img src={postDetail.Image} className='photo' alt='vendor photo'/>
    <div className='icons'>
    {postDetail.Fbook ? <a href={postDetail.Fbook} alt = 'facebook' target = '_blank' rel='noreferrer noopener'><Fbook/></a> : null}
    {postDetail.Insta ? <a href={postDetail.Insta} alt= 'instagram' target = '_blank' rel='noreferrer noopener'><Insta/></a> : null}
    {postDetail.Web ? <a href={postDetail.Web} alt= 'Website URL' target = '_blank' rel='noreferrer noopener'><Web/></a> : null}
    </div>
    </div>
  )
}

export function CakeMaker(postDetail){
  return(
    <div className='CakeMaker'>
    <div className='info'>
    <p>Name: {postDetail.Name}</p>
    <p>Town: {postDetail.Town}</p>
    <p>State: {postDetail.State} </p>
    <p>PostCode: {postDetail.PostCode}</p>
    </div>
    <img src={postDetail.Image} className='photo' alt='vendor photo'/>
    <div className='icons'>
    {postDetail.Fbook ? <a href={postDetail.Fbook} alt = 'facebook' target = '_blank' rel='noreferrer noopener'><Fbook/></a> : null}
    {postDetail.Insta ? <a href={postDetail.Insta} alt= 'instagram' target = '_blank' rel='noreferrer noopener'><Insta/></a> : null}
    {postDetail.Web ? <a href={postDetail.Web} alt= 'Website URL' target = '_blank' rel='noreferrer noopener'><Web/></a> : null}
    </div>
    </div>
  )
}

export function Caterer(postDetail){
  return(
    <div className='Caterers'>
    <div className='info'>
    <p>Name: {postDetail.Name}</p>
    <p>Town: {postDetail.Town}</p>
    <p>State: {postDetail.State} </p>
    <p>PostCode: {postDetail.PostCode}</p>
    </div>
    <img src={postDetail.Image} className='photo' alt='vendor photo'/>
    <div className='icons'>
    {postDetail.Fbook ? <a href={postDetail.Fbook} alt = 'facebook' target = '_blank' rel='noreferrer noopener'><Fbook/></a> : null}
    {postDetail.Insta ? <a href={postDetail.Insta} alt= 'instagram' target = '_blank' rel='noreferrer noopener'><Insta/></a> : null}
    {postDetail.Web ? <a href={postDetail.Web} alt= 'Website URL' target = '_blank' rel='noreferrer noopener'><Web/></a> : null}
    </div>
    </div>
  )
}

export function Celebrant(postDetail){
  return(
    <div className='Celebrants'>
    <div className='info'>
    <p>Name: {postDetail.Name}</p>
    <p>Gender: {postDetail.Gender}</p>
    <p>Town: {postDetail.Town}</p>
    <p>State: {postDetail.State} </p>
    <p>PostCode: {postDetail.PostCode}</p>
    </div>
    <img src={postDetail.Image} className='photo' alt='vendor photo'/>
    <div className='icons'>
    {postDetail.Fbook ? <a href={postDetail.Fbook} alt = 'facebook' target = '_blank' rel='noreferrer noopener'><Fbook/></a> : null}
    {postDetail.Insta ? <a href={postDetail.Insta} alt= 'instagram' target = '_blank' rel='noreferrer noopener'><Insta/></a> : null}
    {postDetail.Web ? <a href={postDetail.Web} alt= 'Website URL' target = '_blank' rel='noreferrer noopener'><Web/></a> : null}
    </div>
    </div>
  )
}

export function DressDesigner(postDetail){
  return(
    <div className='Dressdesign'>
    <div className='info'>
    <p>Name: {postDetail.Name}</p>
    <p>Town: {postDetail.Town}</p>
    <p>State: {postDetail.State} </p>
    <p>PostCode: {postDetail.PostCode}</p>
    </div>
    <img src={postDetail.Image} className='photo' alt='vendor photo'/>
    <div className='icons'>
    {postDetail.Fbook ? <a href={postDetail.Fbook} alt = 'facebook' target = '_blank' rel='noreferrer noopener'><Fbook/></a> : null}
    {postDetail.Insta ? <a href={postDetail.Insta} alt= 'instagram' target = '_blank' rel='noreferrer noopener'><Insta/></a> : null}
    {postDetail.Web ? <a href={postDetail.Web} alt= 'Website URL' target = '_blank' rel='noreferrer noopener'><Web/></a> : null}
    </div>
    </div>
  )
}

export function Firework(postDetail){
  return(
    <div className='Fireworks'>
    <div className='info'>
    <p>Name: {postDetail.Name}</p>
    <p>Town: {postDetail.Town}</p>
    <p>State: {postDetail.State} </p>
    <p>PostCode: {postDetail.PostCode}</p>
    </div>
    <img src={postDetail.Image} className='photo' alt='vendor photo'/>
    <div className='icons'>
    {postDetail.Fbook ? <a href={postDetail.Fbook} alt = 'facebook' target = '_blank' rel='noreferrer noopener'><Fbook/></a> : null}
    {postDetail.Insta ? <a href={postDetail.Insta} alt= 'instagram' target = '_blank' rel='noreferrer noopener'><Insta/></a> : null}
    {postDetail.Web ? <a href={postDetail.Web} alt= 'Website URL' target = '_blank' rel='noreferrer noopener'><Web/></a> : null}
    </div>
    </div>
  )
}

export function Florist(postDetail){
  return(
    <div className='Florists'>
    <div className='info'>
    <p>Name: {postDetail.Name}</p>
    <p>Town: {postDetail.Town}</p>
    <p>PostCode: {postDetail.PostCode}</p>
    </div>
    <img src={postDetail.Image} className = 'photo' alt='vendor photo'/>
    <div className='icons'>
    {postDetail.Fbook ? <a href={postDetail.Fbook} alt = 'facebook' target = '_blank' rel='noreferrer noopener'><Fbook/></a> : null}
    {postDetail.Insta ? <a href={postDetail.Insta} alt= 'instagram' target = '_blank' rel='noreferrer noopener'><Insta/></a> : null}
    {postDetail.Web ? <a href={postDetail.Web} alt= 'Website URL' target = '_blank' rel='noreferrer noopener'><Web/></a> : null}
    </div>
    </div>
  )
}

export function HairAndMakeup(postDetail){
  return(
    <div className='hairandmakeup'>
    <div className='info'>
    <p>Name: {postDetail.Name}</p>
    <p>Town: {postDetail.Town}</p>
    <p>State: {postDetail.State} </p>
    <p>PostCode: {postDetail.PostCode}</p>
    </div>
    <img src={postDetail.Image} className='photo' alt='vendor photo'/>
    <div className='icons'>
    {postDetail.Fbook ? <a href={postDetail.Fbook} alt = 'facebook' target = '_blank' rel='noreferrer noopener'><Fbook/></a> : null}
    {postDetail.Insta ? <a href={postDetail.Insta} alt= 'instagram' target = '_blank' rel='noreferrer noopener'><Insta/></a> : null}
    {postDetail.Web ? <a href={postDetail.Web} alt= 'Website URL' target = '_blank' rel='noreferrer noopener'><Web/></a> : null}
    </div>
    </div>
  )
}

export function Musician(postDetail){
  return(
    <div className='Musicians'>
    <div className='info'>
    <p>Name: {postDetail.Name}</p>
    <p>PostCode: {postDetail.PostCode}</p>
    <p>Style: {postDetail.Style}</p>
    <p>Town: {postDetail.Town}</p>
    <p>Gender: {postDetail.Gender}</p>
    </div>
    <img src={postDetail.Image} className='photo' alt='vendor photo'/>
    <div className='icons'>
    {postDetail.Fbook ? <a href={postDetail.Fbook} alt = 'facebook' target = '_blank' rel='noreferrer noopener'><Fbook/></a> : null}
    {postDetail.Insta ? <a href={postDetail.Insta} alt= 'instagram' target = '_blank' rel='noreferrer noopener'><Insta/></a> : null}
    {postDetail.Web ? <a href={postDetail.Web} alt= 'Website URL' target = '_blank' rel='noreferrer noopener'><Web/></a> : null}
    </div>
    </div>
  )
}

export function Photographer(postDetail){
  return(
    <div className='Photographers'>
    <div className='info'>
    <p>Name: {postDetail.Name}</p>
    <p>PostCode: {postDetail.PostCode}</p>
    <p>Type: {postDetail.Type}</p>
    <p>Town: {postDetail.Town}</p>
    </div>
    <img src={postDetail.Image} className = 'photo' alt='vendor photo'/>
    <div className='icons'>
    {postDetail.Fbook ? <a href={postDetail.Fbook} alt = 'facebook' target = '_blank' rel='noreferrer noopener'><Fbook/></a> : null}
    {postDetail.Insta ? <a href={postDetail.Insta} alt= 'instagram' target = '_blank' rel='noreferrer noopener'><Insta/></a> : null}
    {postDetail.Web ? <a href={postDetail.Web} alt= 'Website URL' target = '_blank' rel='noreferrer noopener'><Web/></a> : null}
    </div>
    </div>
  )
}

export function Prophire(postDetail){
  return(
    <div className='Prophire'>
    <div className='info'>
    <p>Name: {postDetail.Name}</p>
    <p>Town: {postDetail.Town}</p>
    <p>State: {postDetail.State} </p>
    <p>PostCode: {postDetail.PostCode}</p>
    </div>
    <img src={postDetail.Image} className='photo' alt='vendor photo'/>
    <div className='icons'>
    {postDetail.Fbook ? <a href={postDetail.Fbook} alt = 'facebook' target = '_blank' rel='noreferrer noopener'><Fbook/></a> : null}
    {postDetail.Insta ? <a href={postDetail.Insta} alt= 'instagram' target = '_blank' rel='noreferrer noopener'><Insta/></a> : null}
    {postDetail.Web ? <a href={postDetail.Web} alt= 'Website URL' target = '_blank' rel='noreferrer noopener'><Web/></a> : null}
    </div>
    </div>
  )
}

export function Stylist(postDetail){
  return(
    <div className='Stylists'>
    <div className='info'>
    <p>Name: {postDetail.Name}</p>
    <p>Town: {postDetail.Town}</p>
    <p>State: {postDetail.State} </p>
    <p>PostCode: {postDetail.PostCode}</p>
    </div>
    <img src={postDetail.Image} className='photo' alt='vendor photo'/>
    <div className='icons'>
    {postDetail.Fbook ? <a href={postDetail.Fbook} alt = 'facebook' target = '_blank' rel='noreferrer noopener'><Fbook/></a> : null}
    {postDetail.Insta ? <a href={postDetail.Insta} alt= 'instagram' target = '_blank' rel='noreferrer noopener'><Insta/></a> : null}
    {postDetail.Web ? <a href={postDetail.Web} alt= 'Website URL' target = '_blank' rel='noreferrer noopener'><Web/></a> : null}
    </div>
    </div>
  )
}

export function Transport(postDetail){
  return(
    <div className='Transport'>
    <div className='info'>
    <p>Name: {postDetail.Name}</p>
    <p>Town: {postDetail.Town}</p>
    <p>State: {postDetail.State} </p>
    <p>PostCode: {postDetail.PostCode}</p>
    </div>
    <img src={postDetail.Image} className='photo' alt='vendor photo'/>
    <div className='icons'>
    {postDetail.Fbook ? <a href={postDetail.Fbook} alt = 'facebook' target = '_blank' rel='noreferrer noopener'><Fbook/></a> : null}
    {postDetail.Insta ? <a href={postDetail.Insta} alt= 'instagram' target = '_blank' rel='noreferrer noopener'><Insta/></a> : null}
    {postDetail.Web ? <a href={postDetail.Web} alt= 'Website URL' target = '_blank' rel='noreferrer noopener'><Web/></a> : null}
    </div>
    </div>
  )
}

function Venue(postDetail){
  return(
    <div className='Venues'>
    <div className='info'>
    <p>Name: {postDetail.Name}</p>
    <p>Suburb: {postDetail.Suburb}</p>
    <p>PostCode: {postDetail.PostCode}</p>
    <p>Scenery: {postDetail.Scenery}</p>
    <p>Address: {postDetail.Address}</p>
    </div>
    <img src={postDetail.Image} className = 'photo' alt='vendor photo'/>
    <div className='icons'>
    {postDetail.Fbook ? <a href={postDetail.Fbook} alt = 'facebook' target = '_blank' rel='noreferrer noopener'><Fbook/></a> : null}
    {postDetail.Insta ? <a href={postDetail.Insta} alt= 'instagram' target = '_blank' rel='noreferrer noopener'><Insta/></a> : null}
    {postDetail.Web ? <a href={postDetail.Web} alt= 'Website URL' target = '_blank' rel='noreferrer noopener'><Web/></a> : null}
    </div>
    </div>
  )
};

export default Venue;
